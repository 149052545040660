import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Link from 'next/link';
import { routes } from 'services/routes';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import { useSelector } from 'react-redux';
import { selectOfflineReady } from 'redux/Home';
import { useDownloadProducts } from 'services/hooks/useDownloadProducts';
import { Brand } from 'redux/ProductInfo/types';
import { Button } from 'components/Button/Button';
import {
  LeftContainer,
  Logo,
  MobileContainer,
  NonMobileContainer,
  NonMobileItem,
  RightContainer,
  Toolbar,
} from './Header.style';
import { ProductIndicator } from './ProductIndicator';
import { TranslatorButton } from './TranslatorButton';
import { useAddToHomescreenPrompt } from './services';

export const Header = React.memo(() => {
  const offlineReady = useSelector(selectOfflineReady);
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [isPWAInstallable, setPWAInstallable] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [numberOfMenuItems, setNumberOfMenuItems] = React.useState(0);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    if (prompt) {
      setPWAInstallable(true);
      setNumberOfMenuItems(numberOfMenuItems + 1);
    }
  }, [prompt]);

  const { isLoading: isLoadingAltiform } = useDownloadProducts(Brand.ALTIFORM);
  const { isLoading: isLoadingAltiven } = useDownloadProducts(Brand.ALTIVEN);
  const { isLoading: isLoadingALTIPRESS } = useDownloadProducts(Brand.ALTIPRESS);
  const { isLoading: isLoadingKSOFT } = useDownloadProducts(Brand.KSOFT);
  const { isLoading: isLoadingURGOKTWO } = useDownloadProducts(Brand.URGOKTWO);

  const loadingIndicator =
    offlineReady ||
    (!isLoadingAltiform &&
      !isLoadingAltiven &&
      !isLoadingALTIPRESS &&
      !isLoadingKSOFT &&
      !isLoadingURGOKTWO);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const renderMobileMenu = (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {isPWAInstallable ? <MenuItem onClick={promptToInstall}>Add to homescreen</MenuItem> : null}
    </Menu>
  );

  return (
    <AppBar elevation={1} position="static" color="default">
      <Toolbar>
        <LeftContainer>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <Link href={routes.HOME}>
              <Logo alt="Urgo logo" src="/images/Measure_and_Go_Logo_Long.png" />
            </Link>
          </IconButton>
        </LeftContainer>
        <RightContainer>
          <NonMobileContainer>
            <div style={{ display: 'flex' }}>
              {isPWAInstallable ? (
                <NonMobileItem>
                  <Button onClick={promptToInstall}>Add to homescreen</Button>
                </NonMobileItem>
              ) : null}
              <NonMobileItem>
                <TranslatorButton />
              </NonMobileItem>
            </div>
          </NonMobileContainer>
          <MobileContainer>
            {numberOfMenuItems > 0 ? (
              // Prevent showing icon to online menu if no item in menu
              <IconButton
                size="medium"
                aria-label="show more"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MenuIcon />
              </IconButton>
            ) : null}
          </MobileContainer>
        </RightContainer>
      </Toolbar>
      {renderMobileMenu}
    </AppBar>
  );
});

Header.displayName = 'Header';
