import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { useWindowSize } from 'services/hooks/useWindowSize';
import { STEP, useStepperInfo } from './service';
import { Container } from './RecapStepper.style';

interface Props {
  step: STEP;
}

export const RecapStepper = React.memo<Props>(({ step }) => {
  const { stepLabels, currentStepIndex } = useStepperInfo(step);
  const { isDesktop } = useWindowSize();

  if (!isDesktop) {
    return null;
  }

  return (
    <Container>
      <Stepper activeStep={currentStepIndex} orientation="vertical">
        {stepLabels.map(stepLabel => (
          <Step key={stepLabel}>
            <StepLabel>{stepLabel}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Container>
  );
});

RecapStepper.displayName = 'RecapStepper';

export { STEP };
