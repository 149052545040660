import styled from 'styled-components';
import { Header } from 'components/Header';
import { borderRadius, breakpoints, colorPalette, getSpacing } from 'stylesheet';
import React, { ReactNode } from 'react';
import { HomeFooter } from 'components/HomeFooter/HomeFooter';
import { RecapStepper, STEP } from 'components/RecapStepper/RecapStepper';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
Container.displayName = 'Container';

export const PageContent = styled.main`
  background-color: ${colorPalette.blueLight};
  border-radius: ${borderRadius.large};
  flex-grow: 1;
  padding: ${getSpacing(1)};

  @media (min-width: ${breakpoints.tablet}) {
    padding: ${getSpacing(6)};
  }
`;

export const RecapContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

PageContent.displayName = 'PageContent';

interface Props {
  step?: STEP;
  children: ReactNode;
}

export const Layout = React.memo<Props>(({ step, children }) => (
  <Container>
    <Header />
    <RecapContainer>
      {step !== undefined && <RecapStepper step={step} />}
      <PageContent>{children}</PageContent>
    </RecapContainer>
    <HomeFooter />
  </Container>
));

Layout.displayName = 'Layout';
