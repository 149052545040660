import { IntlShape } from 'react-intl';

export const getMailHrefFromContactPurpose = (contactPurpose: string, intl: IntlShape): string =>
  `mailto:${intl.formatMessage({
    id: `home.footer.${contactPurpose}.email`,
  })}?subject=${intl.formatMessage({
    id: `home.footer.${contactPurpose}.subject`,
  })}&body=${intl.formatMessage({
    id: `home.footer.${contactPurpose}.body`,
  })}`;

export const showCookieBotBanner = (): void => {
  // I know this is dangerous, but the CookieBot system should have been loaded before this code is reached.
  // If you cannot launch the cookie bot banner in dev, it most likely is because you do not have the correct host file.
  // Refer to the quick start doc for more information.

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  window.Cookiebot.renew();
};
