import styled from 'styled-components';
import { colorPalette, getSpacing, TABLET_WIDTH, typography } from 'stylesheet';
import Typography from '@material-ui/core/Typography';

export const Container = styled.footer`
  display: flex;
  align-items: center;
  width: 100%;
  background: ${colorPalette.secondary};
  padding: ${getSpacing(3)} ${getSpacing(10)};
  ${typography.light}
  ${typography.small}
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: ${TABLET_WIDTH}px) {
    padding: ${getSpacing(3)} ${getSpacing(5)};
  }
`;
Container.displayName = 'Container';

export const RightSideContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  height: ${getSpacing(14)};

  @media (max-width: ${TABLET_WIDTH}px) {
    height: ${getSpacing(10)};
    padding-left: ${getSpacing(3)};
  }
`;
Logo.displayName = 'Logo';

export const ContactLink = styled.a`
  text-decoration: none;
`;
ContactLink.displayName = 'ContactLink';

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledInlineTypography = styled(Typography)`
  display: inline;
  color: ${colorPalette.white};

  @media (max-width: ${TABLET_WIDTH}px) {
    font-size: 10px;
  }
`;

export const StyledInlineLink = styled(Typography)`
  display: inline;
  text-decoration: underline;
  color: ${colorPalette.white};

  @media (max-width: ${TABLET_WIDTH}px) {
    font-size: 10px;
  }
`;

export const StyledVerticalLine = styled.div`
  border-left: 1px solid black;
  height: 50px;
  margin: 0 20px;
`;

export const TextAlignRightContainer = styled.div`
  text-align: right;
`;
