import { useIntl } from 'react-intl';
import { FunctionComponent } from 'react';
import {
  ColumnContainer,
  ContactLink,
  Container,
  Logo,
  RightSideContainer,
  StyledInlineLink,
  StyledInlineTypography,
  StyledVerticalLine,
  TextAlignRightContainer,
} from './HomeFooter.style';
import { CONTACT_PURPOSES } from './constants';
import { getMailHrefFromContactPurpose, showCookieBotBanner } from './service';

export const HomeFooter: FunctionComponent = () => {
  const intl = useIntl();
  return (
    <Container>
      <a
        href={intl.formatMessage({
          id: `home.footer.urgo_website`,
        })}
        target="_blank"
        rel="noreferrer"
      >
        <Logo alt="Urgo logo" src="/images/urgo-white-logo.png" />
      </a>
      <RightSideContainer>
        <ColumnContainer>
          {CONTACT_PURPOSES.map(contactPurpose => (
            <ContactLink
              key={contactPurpose}
              href={getMailHrefFromContactPurpose(contactPurpose, intl)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TextAlignRightContainer>
                <StyledInlineLink variant="body2">
                  {intl.formatMessage({
                    id: `home.footer.${contactPurpose}.contact_button_text`,
                  })}
                </StyledInlineLink>
              </TextAlignRightContainer>
            </ContactLink>
          ))}
        </ColumnContainer>
        <StyledVerticalLine />
        <ColumnContainer>
          <div>
            <StyledInlineTypography variant="body2">
              {intl.formatMessage({
                id: `home.footer.privacy_notice.start_of_sentence`,
              })}
            </StyledInlineTypography>
            <ContactLink
              href={intl.formatMessage({
                id: `home.footer.privacy_notice.healthcare_professional_link`,
              })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledInlineLink variant="body2">
                {intl.formatMessage({
                  id: `home.footer.privacy_notice.healthcare_professional_text`,
                })}
              </StyledInlineLink>
            </ContactLink>
            <StyledInlineTypography variant="body2">
              {intl.formatMessage({
                id: `home.footer.privacy_notice.and_separator`,
              })}
            </StyledInlineTypography>
            <ContactLink
              href={intl.formatMessage({
                id: `home.footer.privacy_notice.patient_link`,
              })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledInlineLink variant="body2">
                {intl.formatMessage({
                  id: `home.footer.privacy_notice.patient_text`,
                })}
              </StyledInlineLink>
            </ContactLink>
          </div>
          <div>
            <ContactLink
              href={intl.formatMessage({
                id: `home.footer.terms_of_use.tou_link`,
              })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledInlineLink variant="body2">
                {intl.formatMessage({
                  id: `home.footer.terms_of_use.tou_text`,
                })}
              </StyledInlineLink>
            </ContactLink>
            <StyledInlineTypography variant="body2">
              {intl.formatMessage({
                id: `home.footer.terms_of_use.and_separator`,
              })}
            </StyledInlineTypography>
            <ContactLink
              href={intl.formatMessage({
                id: `home.footer.terms_of_use.data_privacy_link`,
              })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledInlineLink variant="body2">
                {intl.formatMessage({
                  id: `home.footer.terms_of_use.data_privacy_text`,
                })}
              </StyledInlineLink>
            </ContactLink>
          </div>
          <div>
            <ContactLink onClick={showCookieBotBanner} href="#">
              <StyledInlineLink variant="body2">
                {intl.formatMessage({
                  id: `home.footer.cookie-management.text`,
                })}
              </StyledInlineLink>
            </ContactLink>
          </div>
        </ColumnContainer>
      </RightSideContainer>
    </Container>
  );
};
