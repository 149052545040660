import { IntlShape, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  selectAltipressStyleOptions,
  selectCompressionClass,
  selectProductBrand,
  selectProductSizes,
  selectSizeCategory,
  selectStyleOptions,
} from 'redux/ProductInfo';
import {
  AltipressStyleOptions,
  ProductSizes,
  SizeCategoryType,
  StyleOptions,
} from 'redux/ProductInfo/types';

export const getSizeLabel = (
  intl: IntlShape,
  sizeCategory: undefined | SizeCategoryType,
  productSizes: ProductSizes,
): string | undefined => {
  switch (sizeCategory) {
    case undefined:
      return undefined;
    case SizeCategoryType.MADE_TO_MEASURE:
      return intl.formatMessage({ id: 'recap-stepper.made-to-measure' });
    case SizeCategoryType.READY_TO_WEAR:
      if (productSizes.length === 0) {
        return intl.formatMessage({ id: 'recap-stepper.ready-to-wear' });
      }
      return productSizes.join(', ');
    default:
      return undefined;
  }
};

const getStyleOptionsLabel = (
  styleOptions: StyleOptions | undefined,
  altipressStyleOptions: AltipressStyleOptions | undefined,
  intl: IntlShape,
): string => {
  if (styleOptions !== undefined) {
    return `${styleOptions.colour}\n${styleOptions.lengthStyle}\n${styleOptions.toeStyle}`;
  } else if (altipressStyleOptions !== undefined) {
    const outerColor = altipressStyleOptions.outerColour;
    const outerColorText =
      outerColor !== undefined
        ? `${intl.formatMessage({
            id: 'recap-stepper.steps.altipress-style-option.outer-colour',
          })}: ${outerColor}\n`
        : '';
    const innerColor = altipressStyleOptions.innerColour;
    const innerColorText =
      innerColor !== undefined
        ? `${intl.formatMessage({
            id: 'recap-stepper.steps.altipress-style-option.inner-colour',
          })}: ${innerColor}`
        : '';

    return `${outerColorText}${innerColorText}`;
  }
  return intl.formatMessage({ id: 'recap-stepper.steps.styleOptions' });
};

type StepLabelMapping = Record<STEP, string>;

interface StepperInfo {
  stepLabels: string[];
  stepLabelMapping: StepLabelMapping;
  currentStepIndex: number;
}

export enum STEP {
  BRAND = 'brand',
  CLASS = 'class',
  STYLE_OPTIONS = 'styleOptions',
  SIZE = 'size',
}

const STEP_ORDER: STEP[] = [STEP.BRAND, STEP.CLASS, STEP.STYLE_OPTIONS, STEP.SIZE];

export const useStepperInfo = (activeStepName: STEP): StepperInfo => {
  const intl = useIntl();

  const styleOptions = useSelector(selectStyleOptions);
  const altipressStyleOptions = useSelector(selectAltipressStyleOptions);
  const brand = useSelector(selectProductBrand);
  const compressionClass = useSelector(selectCompressionClass);
  const sizeCategory = useSelector(selectSizeCategory);
  const productSizes = useSelector(selectProductSizes);

  const currentStepIndex = STEP_ORDER.indexOf(activeStepName);

  const stepLabelMapping: StepLabelMapping = {
    brand:
      currentStepIndex <= STEP_ORDER.indexOf(STEP.BRAND) || brand === undefined
        ? intl.formatMessage({ id: 'recap-stepper.steps.brand' })
        : `${brand.charAt(0).toUpperCase()}${brand.slice(1)}`,
    class:
      currentStepIndex <= STEP_ORDER.indexOf(STEP.CLASS) || compressionClass === undefined
        ? intl.formatMessage({ id: 'recap-stepper.steps.class' })
        : compressionClass,
    styleOptions:
      currentStepIndex <= STEP_ORDER.indexOf(STEP.STYLE_OPTIONS) ||
      (styleOptions === undefined && altipressStyleOptions === undefined)
        ? intl.formatMessage({ id: 'recap-stepper.steps.styleOptions' })
        : getStyleOptionsLabel(styleOptions, altipressStyleOptions, intl),
    size:
      getSizeLabel(intl, sizeCategory, productSizes) ??
      intl.formatMessage({ id: 'recap-stepper.steps.size' }),
  };

  const stepLabels = STEP_ORDER.map(step => stepLabelMapping[step]);

  return { currentStepIndex, stepLabelMapping, stepLabels };
};
