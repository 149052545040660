import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectProductBrand, selectSizeCategory } from 'redux/ProductInfo';
import { Brand, SizeCategoryType } from 'redux/ProductInfo/types';

export const isReadyToWear = (product: RTWProduct | M2MProduct): product is RTWProduct =>
  (product as RTWProduct)['PIP code'] !== undefined;

export interface RTWProduct {
  'PIP code': string;
  'Product code': string;
  Class: string;
  Type?: string;
  Toe?: string;
  Size: string;
  Colour: string;
  'Unit of sale': string;
  'NPC code'?: string;
  Brand: string;
  Length?: string;
  'Latex Free'?: boolean;
  'Reduced Compression'?: boolean;
  Width?: string;
}

export interface M2MProduct {
  'Product code': string;
  Class: string;
  Type: string;
  Toe: string;
  Colour: string;
  Brand: string;
}

interface DownloadHook {
  products: RTWProduct[] | M2MProduct[];
  isLoading: boolean;
  error: string;
}

const RTW_URL_BY_BRAND = {
  [Brand.ALTIFORM]: '/data/altiform-data.json',
  [Brand.ALTIVEN]: '/data/altiven-stock.json',
  [Brand.URGOKTWO]: '/data/bandage-data.json',
  [Brand.KSOFT]: '/data/bandage-data.json',
  [Brand.ALTIPRESS]: '/data/altipress.json',
};

const M2M_URL_BY_BRAND = {
  [Brand.ALTIFORM]: '/data/altiform-m2m-data.json',
  [Brand.ALTIVEN]: '/data/altiven-m2m.json',
  [Brand.ALTIPRESS]: '/data/altipress-m2m.json',
  [Brand.URGOKTWO]: '/data/bandage-data.json',
  [Brand.KSOFT]: '/data/bandage-data.json',
};

export const useDownloadProducts = (inputBrand?: Brand): DownloadHook => {
  const brand = inputBrand !== undefined ? inputBrand : useSelector(selectProductBrand);
  const sizeCategory = useSelector(selectSizeCategory);
  const [products, setProducts] = useState<RTWProduct[] | M2MProduct[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchProducts = async () => {
      if (brand === undefined) {
        setError('No brand selected');
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const url =
          sizeCategory === SizeCategoryType.MADE_TO_MEASURE
            ? M2M_URL_BY_BRAND[brand]
            : RTW_URL_BY_BRAND[brand];
        const response = await axios.get<RTWProduct[]>(url);
        setProducts(response.data);
        setError('');
      } catch {
        setError('Failed to fetch products');
      } finally {
        setIsLoading(false);
      }
    };

    void fetchProducts();
  }, [brand]);

  return { products, isLoading, error };
};
