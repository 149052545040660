import React from 'react';

import { Button } from 'components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { selectTranslatorMode, setTranslatorMode } from 'redux/TranslatorMode';
import { useWindowSize } from 'services/hooks/useWindowSize';

export const TranslatorButton = React.memo(() => {
  const dispatch = useDispatch();
  const isTranslatorModeEnabled = useSelector(selectTranslatorMode);
  const { isMobile } = useWindowSize();

  const translatorButtonText = isMobile
    ? 'Translate'
    : `${isTranslatorModeEnabled ? 'Disable' : 'Enable'} Translator mode`;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const showTranslatorButton = ['staging', 'dev'].includes(process.env.ENVIRONMENT);

  const toggleTranslatorMode = () => {
    dispatch(setTranslatorMode(!isTranslatorModeEnabled));
  };

  if (showTranslatorButton) {
    return <Button onClick={toggleTranslatorMode}>{translatorButtonText}</Button>;
  }
  return null;
});

TranslatorButton.displayName = 'TranslatorButton';
