import styled from 'styled-components';
import { colorPalette, getSpacing } from 'stylesheet';

const steperColor = colorPalette.greyLight;

export const Container = styled.div`
  padding-top: 30vh;
  white-space: pre;
  background-color: ${steperColor};
  & > * {
    background-color: ${steperColor};
    border-radius: ${getSpacing(1)};
    max-height: 400px;
  }
`;
