import { useEffect, useState } from 'react';
import { DESKTOP_WIDTH, TABLET_WIDTH } from 'stylesheet';

interface WindowSize {
  width: number | undefined;
  height: number | undefined;
}

interface ReturnType {
  windowSize: WindowSize;
  isMobile: boolean;
  isDesktop: boolean;
}

export const useWindowSize = (): ReturnType => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = windowSize.width !== undefined && windowSize.width < TABLET_WIDTH;
  const isDesktop = windowSize.width !== undefined && windowSize.width >= DESKTOP_WIDTH;

  return { windowSize, isMobile, isDesktop };
};
