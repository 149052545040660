import styled from 'styled-components';
import { getSpacing, TABLET_WIDTH } from 'stylesheet';
import BaseToolbar from '@material-ui/core/Toolbar';

export const Logo = styled.img`
  height: ${getSpacing(9)};
`;
Logo.displayName = 'Logo';

export const Toolbar = styled(BaseToolbar)`
  display: flex;
  justify-content: space-between;
`;

export const LeftContainer = styled.div`
  display: flex;
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-left: ${getSpacing(3)};
  }
`;

export const MobileContainer = styled.div`
  display: inherit;

  @media (min-width: ${TABLET_WIDTH}px) {
    display: none;
  }
`;

export const NonMobileContainer = styled.div`
  display: flex;
  justify-content: space-around;

  @media (max-width: ${TABLET_WIDTH}px) {
    display: none;
  }
`;

export const NonMobileItem = styled.div`
  margin-left: ${getSpacing(2)};
`;
